<template>
<b-container fluid>
  <b-row class="text-white bg-kpmg-blue py-4 mb-4">
    <b-col>
      <h1 class="kstyle">RIC Lists</h1>
    </b-col>
  </b-row>
  <b-row v-if="!permissions.read">
    <b-col>
      <h1 class="kstyle">No permissions for this page</h1>
    </b-col>
  </b-row>
  <b-row v-if="permissions.read">
    <b-container>
      <b-row class="text-center mb-4" align-h="center" v-if="loading">
        <b-col cols="1">
          <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
        </b-col>
      </b-row>
      <b-row class="mb-4" v-if="!loading">
        <b-col>
          <b-card class="mb-3">
            <b-form-group horizontal class="mt-4">
              <b-input-group>
                <b-form-input v-model="filter" placeholder="Type to search" />
                  <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
              </b-input-group>
            </b-form-group>
          </b-card>
          <b-card >
            <b-button class="mb-4 button" v-if="permissions.add" variant="outline-primary" :to="{ name: 'FormNew', params: { model: 'riclist' }}">add list</b-button>
            <div v-for="item in show" :key="'item' + item.id">
              <div class="mb-3">
                <b-link :to="{ name: 'Riclist', params: { id: item.id }}">{{item.name}}</b-link>
                <b-button size="sm" class="ml-3" v-if="permissions.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'riclist' }}">edit</b-button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-row>
</b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'

export default {
  name: 'Ricklists',
  computed: {
    show: function () {
      let result = this.items
      if (this.filter.length > 0) {
        result = _.filter(result, x => {
          return x.name.toUpperCase().includes(this.filter.toUpperCase())
        })
      }
      return result
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "KPMG Regulatory Horizon: RIC Lists"
    this.$store.commit('setBrand', 'Regulatory Horizon RIC Lists')
    this.$stat.log({ page: 'ricklists', action: 'open ricklists' })
    this.permissions.add = ac.can(this.user.acgroups).createAny('riclist').granted
    this.permissions.edit = ac.can(this.user.acgroups).updateAny('riclist').granted
    this.permissions.read = ac.can(this.user.acgroups).readAny('riclist').granted
    this.load()
  },
  data () {
    return {
      buttonvariant: 'primary',
      event: {},
      filter: '',
      loading: true,
      items: '',
      permissions: {
        add: false,
        edit: false,
        read: false
      }
    }
  },
  methods: {
    load: async function () {
      this.$logger.debug('load started')
      this.loading = true
      try {
        this.items = await this.$Amplify.API.get('cosmos', '/standard/riclist')
        this.loading = false
        this.$logger.debug(this.items)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
